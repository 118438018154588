<template>
	<div>
		<div class="page--service container container-l mx-auto">
			<section>
				<hero :title="service.title" :text="service.discription">
					<img class="mx-auto" :src="service.main_media" alt="" />
				</hero>
			</section>
			<section v-if="service.tecnologies.length" class="tecnology-section">
				<h2 class="title text-2xl sm:text-4xl xl:text-6xl">
					{{ $t("headings.usedTechnologies") }}
				</h2>
				<div>
					<tecnologies :tecnologies="service.tecnologies" />
				</div>
			</section>
			<div class="flex justify-center">
				<button class="btn is-main nav-btn">
					<router-link to="/contact-us">
						Get Quotation Now
					</router-link>
				</button>
			</div>
		</div>
	</div>
</template>
<script>
import Tecnologies from "../components/Tecnologies.vue";
import Hero from "../components/Hero.vue";

export default {
	components: {
		Tecnologies,
		Hero
	},

	data() {
		return {
			service: {
				title: "Mobile",
				discription:
					"Native or Flutter? Whatever your answer, we appreciate both. We will advise you on the best one for your project.",
				main_media: "images/mop.svg",
				tecnologies: [
					{ id: 3, image: "images/Java.png", name: "Java" },
					{ id: 4, image: "images/Kotlin.png", name: "Kotlin" },
					{ id: 5, image: "images/Swift.png", name: "Swift" },
					{ id: 6, image: "images/Dart.png", name: "Dart" },
					{ id: 7, image: "images/Javascript.jpg", name: "Javascript" },
					{ id: 8, image: "images/Android studio.png", name: "Android studio" },
					{ id: 9, image: "images/Xcode.png", name: "Xcode" },
					{
						id: 10,
						image: "images/Visual Studio code.jpg",
						name: "Visual Studio code"
					},
					{ id: 11, image: "images/android.png", name: "Android" },
					{ id: 12, image: "images/IOS.jpg", name: "IOS" },
					{ id: 13, image: "images/Flutter.png", name: "Flutter" }
				]
			},
			selectedSection: null,
			chunk: [],
			chunks: []
		};
	}
};
</script>
